import React from "react";
import styled from "styled-components";

const ContactContainer = styled.section`
  text-align: center;
  padding: 20px;
`;

const ContactHeading = styled.h2`
  margin-bottom: 20px;
`;

const ContactInfo = styled.p`
  margin: 5px 0;
  line-height: 1.6;
`;

const ContactInfoBold = styled(ContactInfo)`
  font-weight: bold;
`;

const ContactDetails: React.FC = () => {
  return (
    <ContactContainer>
      <ContactHeading>Ota Yhteyttä</ContactHeading>
      <ContactInfo>Osoite: Honkajoentie 1, Uimaharju, 81280</ContactInfo>
      <ContactInfo>Puhelin: 013 771604</ContactInfo>
      <ContactInfoBold>Aukioloajat:</ContactInfoBold>
      <ContactInfo>Ma: 08.30–16.00</ContactInfo>
      <ContactInfo>Ti–To: 08.30–18.00</ContactInfo>
      <ContactInfo>Pe: 08.30–19.00</ContactInfo>
      <ContactInfo>La: 14.00–18.00</ContactInfo>
      <ContactInfo>Su: 14.00–19.00</ContactInfo>
      <ContactInfo>
        Ruokailla saa sisällä koko aukioloajan! Tervetuloa nauttimaan hyvästä
        ruoasta ja rennosta tunnelmasta!
      </ContactInfo>
    </ContactContainer>
  );
};

export default ContactDetails;
